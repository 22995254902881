@import url("https://fonts.googleapis.com/css2?family=Edu+VIC+WA+NT+Beginner:wght@400;500;600;700&family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.halfColoredProgressBar {
  background: linear-gradient(to right, green 50%, #c5c5c5 50%);
}

.parallelogram {
  transform: skew(-20deg);
}

#signInDiv {
  display: inline-block;
  background: white;
  color: #444;
  border-radius: 5px;
  /* border: thin solid #888; */
  /* box-shadow: 1px 1px 1px grey; */
  white-space: nowrap;
  width: "300px";
}

@layer components {
  .input-nt {
    @apply focus:outline-none focus:border-blue-300 w-full bg-transparent border border-[gray] rounded-lg p-[12px] min-w-[100px];
  }
}
@layer components {
  .btn-nt {
    @apply bg-[#ff1818] rounded-[12px] py-[14px] px-[2%] hover:bg-[#1f1c1c] hover:shadow-lg p-1 font-bold text-white cursor-pointer duration-500 text-center;
  }
}
@layer components {
  .form-intro-wrap-nt {
    @apply w-[80%] h-[80vh] flex flex-col lg:flex-row overflow-auto rounded-[12px] shadow-[5px_5px_30px_-5px_rgba(0,0,0,0.5)];
  }
}
